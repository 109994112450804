<template>
    <v-container fluid>
        <v-row row wrap>
            <template v-if="loading">
                <v-col cols="12" class="text-center" mt-4>
                <v-progress-circular
                    :width="3"
                    color="blue"
                    indeterminate
                ></v-progress-circular>
                </v-col>
            </template>
            <template v-else>



                    <v-col cols="12" :md="5" mt-0 ml-2 mr-2>
                        <v-text-field
                            v-model="search"
                            append-icon="search"
                            label="Search members by name or #"
                            outlined
                            hide-details
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-card raised>
                        <v-data-table
                            :headers="headers"
                            :items="filteredMembers"
                            :mobile-breakpoint="0"
                            item-key="uid"
                            :expanded="expanded"
                            :search="search"
                            @click:row="expandRow"
                        >
                            <template v-slot:expanded-item="{ item }">
                                <td :colspan="headers.length">
                                    <membership-actions 
                                    :member="item"
                                    item-key="uid"
                                    />
                                </td>
                            </template>

                            <v-alert slot="no-results" :value="true" color="error" icon="warning">
                                Your search for "{{ search }}" found no results.
                            </v-alert>
                            
                        </v-data-table>
                        </v-card>
                            <VueJsonToCsv
                                :json-data="filteredMembers"
                                :csv-title="exportName"
                            >
                                <v-btn color="primary" outlined class="mr-6">
                                    Export Data <i class="mdi mdi-export" aria-hidden="true"></i>
                                </v-btn>
                            </VueJsonToCsv>
                    </v-col>

                    <v-col ma-3>
                        <div class="text-center">        
                            <v-btn icon color="primary" @click="refresh" :loading="loading" :disabled="loading">
                                <v-icon>refresh</v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                    </v-tab-item>

                    <v-tab >Renewals</v-tab>
                    <v-tab-item>
                        <renewals/>
                    </v-tab-item>

                    <v-tab >Invitations</v-tab>
                    <v-tab-item>
                        <invitations/>
                    </v-tab-item>


                </v-tabs>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import moment from 'moment-timezone'
import MembershipActions from './MembershipActions.vue'
import Invitations from './Invitations.vue'
import Renewals from './Renewals.vue'
import VueJsonToCsv from 'vue-json-to-csv'
import AllMixins from '../../mixins/AllMixins.js'
export default {
    data () {
        return {
            search: '',
            headers: [
                { width: '1%', text: '#', align: 'left', sortable: true, value: 'memberId' },
                { text: 'First', align: 'left', sortable: true, value: 'firstName' , },
                { text: 'Last', align: 'left', sortable: true, value: 'lastName',  },
            ],
            currentMembershipYear: "2021",
            currentMembershipStatus: "Member",
            extensionFilter: "All",
            newOrRenew: "All",
            expanded: [],
            extendSwitch: false,
            extensionUpdateLoading: false,
            editMember: "",
            enableGift: false,
            giftee: {},
            membershipYearOptions: ["2019", "2020", "2021", "2022"],
            membershipStatusOptions: ["Member", "Invitee"],
            newOrRenewOptions: ["All", "Renewed From Last Year", "Not Renewed Next Year", "New Additions Only"],
            showMembers: true,
            loading: false,
            confirmGift: false,
            giftLoading: false,
            queryFutureMembers: true,
            members: [],
        }
    },
    components: {
        MembershipActions,
        Invitations,
        Renewals,
        VueJsonToCsv
    },
    mounted () {
        this.refresh()
    },
    mixins: [AllMixins],
    computed: {
        //...mapGetters([
        //    'members'
        //]),
        filteredMembers () {
            let filteredMembers = []
            let self = this
            this.members.forEach( function (member) {
                if(self.isMember(member, self.currentMembershipYear, self.currentMembershipStatus, s)){
                    filteredMembers.push(member);
                }
            })
            return filteredMembers
        },
        filteredExport () {
            let filteredOrders = []
            this.filteredMembers.forEach( member => {
                member.openOrders.forEach( order => {
                    filteredOrders.push({
                        "dateOrdered": order.dateOrdered,
                        "lastName": member.lastName,
                        "firstName": member.firstName,
                        "productId": order.productId,
                        "variation": order.line_items[0].variation_name,
                        "qty": order.line_items[0].quantity,
                        "total": (order.payment.amount/100.0).toLocaleString("en-US", {style:"currency", currency:"USD"})
                    })
                })
            })
            return filteredOrders
        },
        exportName () {
            return "botls_members_export_" + moment.now()
        },
    },
    methods: {
        expandRow (item) {
            // would be
            // this.expanded = [item]
            // but if you click an expanded row, you want it to collapse
            this.expanded = item === this.expanded[0] ? [] : [item]
        },
        isMember(member, year, status, history, extension){

            if(member.userStatus == undefined || member.userStatus != "member" || member.memberId == 0)
                return false
            if(member.userStatus == "invitee"){
                return (status == "Invitee")
            }
            if(status == "Member"){
                let start = member.joinDate
                let end = member.expireDate
                if(start == undefined)
                    start = "2019-01-01"
                if(end == undefined)
                    end = "2019-12-31"
                let midYear = year + "-06-15" //midyear
                let isMember =  (midYear > start && midYear < end) 
                let renewedLast = start < (year + "-01-01")
                let renewedNextNot = end <= (year + "-12-31")
                if(isMember){ //is a member during the year and status selected
                    if(history == "All" || 
                        (history == "Renewed From Last Year" && renewedLast) ||
                        (history == "Not Renewed Next Year" && renewedNextNot) || 
                        (history == "New Additions Only" && !renewedLast)){
                            if(extension == "All" || (extension == "Yes" && member.extended) || 
                                (extension == "No" && !member.extended)){
                                    return true
                            }
                    }
                }
                return false
            }
        },
        refresh () {
            /*
            this.loading = true
            api.getAdminMembers(true)
            .then( () => {
                this.loading = false
            })
            .catch ( error => {
                console.log(error)
            })
            */
           this.getAllUsers()
        },
        calendar (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").calendar()
        },
        async getAllUsers () {
            this.loading = true

            var functionsCall = global.FIREBASE.functions.httpsCallable('membersalesreport')
            let result = await functionsCall()
            this.loading = false
            if(result.data.status == "success"){
                this.members = result.data.members
            }
            else{
                console.log(result.data.error)
                this.members = []
            }
        

        },
    }
}
</script>